import { Button } from "antd";
import Image from "next/image";
import Link from "next/link";
import { motion } from "framer-motion";
import styles from "@buddieshr/styles/components/individualAppVignette.module.scss";

const IndividualAppVignette = ({
  app,
  className,
  withDescription = true,
  withAction = true,
  withLastName = true,
}) => (
  <div
    className={`${styles.itemWrapper}${
      className && className.length > 0 ? ` ${className}` : ""
    }`}
    style={{ background: `${app.lighterColor}63` }}
  >
    <div className={styles.itemInner}>
      <motion.div
        // whileHover={["grow"]}
        // initial={"first"}
        // animate={"second"}
        // transition={{
        //   repeat: Infinity,
        //   repeatDelay: 0,
        //   duration: 3,
        //   repeatType: "reverse",
        // }}
        variants={{
          grow: {
            scale: 1.1,
          },
          second: { opacity: 1, scale: 1.05, y: -10 },
          first: { opacity: 1, scale: 1, y: 10 },
        }}
      >
        <div className={styles.itemLogo}>
          <Image
            src={app.logoUrl}
            width={120}
            height={120}
            alt={`logo for ${app.name}`}
          />
        </div>
      </motion.div>

      <div
        className={styles.itemName}
        style={{
          backgroundColor: `${app.lighterColor}a5`,
          marginBottom: withLastName ? 4 : 12,
        }}
      >
        {app.name}
      </div>
      {withLastName && (
        <div
          style={{ backgroundColor: `${app.lighterColor}a5` }}
          className={styles.lastName}
        >
          {app.lastName}
        </div>
      )}
      {withDescription && (
        <div className={styles.itemTitle}>{app.shortTitleValue}</div>
      )}
      <div className={styles.itemVerb}>{app.verb}</div>
      {withAction && (
        <div className={styles.itemAction}>
          <Link href={`/${app.slug}`} alt={app.shortTitle}>
            <Button type="primary" style={{ padding: "4px 14px" }}>
              View {app.name} app
            </Button>
          </Link>
        </div>
      )}
    </div>
  </div>
);

export default IndividualAppVignette;
