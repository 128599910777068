import React, { useContext, useEffect, useState } from "react";
import styles from "@buddieshr/styles/components/addToSlackButton.module.scss";
import { Button } from "antd";
import Image from "next/image";
import { useTranslation } from "react-i18next";
import SourceContext from "../../sourceContext";
import { getInstallLink } from "@buddieshr/utils/utils";
import { ANALYTICS_EVENTS } from "@buddieshr/utils/constants";
import { gtagEvent } from "@buddieshr/utils/gtag";

const AddToSlackButton = ({
  medium = false,
  small = false,
  text = null,
  style = {},
  app,
  block = false,
}) => {
  const { t } = useTranslation();
  const refSource = useContext(SourceContext);
  const [installLink, setInstallLink] = useState(null);

  useEffect(() => {
    setInstallLink(getInstallLink(refSource, app.installSlackLink));
  }, [app, refSource]);

  return (
    <a
      href={installLink}
      target="_blank"
      rel="noopener noreferrer"
      className={`${styles.mainLink} ${small || medium ? styles.small : ""}`}
      onClick={() => {
        gtagEvent({
          // action: ANALYTICS_EVENTS.ADD_TO_SLACK,
          action: `${ANALYTICS_EVENTS.ADD_TO_SLACK}_${app?.slug.toUpperCase()}`,
          category: "register",
          label: "AddToSlackButton",
        });
        gtagEvent({
          action: ANALYTICS_EVENTS.ADD_TO_SLACK,
          category: "register",
          label: "AddToSlackButton",
        });
      }}
    >
      <Button
        type="primary"
        size={small ? "small" : medium ? "" : "large"}
        style={style}
        className={styles.mainCTA}
        {...(block ? { block: true } : {})}
      >
        <div className={styles.inner}>
          {text || t("addToSlack")}
          &nbsp;
          <Image
            className={styles.slackIconInButton}
            src="/img/slack_icon.png"
            width={small ? 16 : 24}
            height={small ? 16 : 24}
            alt="connect employees on slack"
          />
        </div>
      </Button>
    </a>
  );
};

export default AddToSlackButton;
