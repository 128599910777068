import styles from "@buddieshr/styles/components/features.module.scss";
import { Tag } from "antd";
import Image from "next/image";

const Features = ({ app }) => {
  const { features, fullName } = app;
  return (
    <div id="features" style={{ maxWidth: "100%" }}>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <h2 className={styles.title}>{`Features of ${fullName}`}</h2>
          {/* <div className={styles.subtitle} style={{ width: 620 }}></div> */}
          <div className={styles.list}>
            {features.map((f) => (
              <div key={f.name} className={styles.element}>
                {f.isSuperPower && (
                  <Tag
                    color="volcano"
                    bordered={false}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      top: "-8px",
                      right: "18px",
                      width: "fit-content",
                    }}
                  >
                    <div
                      style={{
                        fontSize: 32,
                        marginRight: 12,
                      }}
                    >
                      🌟
                    </div>
                    <div style={{ fontWeight: "bold" }}>
                      {`${app.name}'s super power!`}
                    </div>
                  </Tag>
                )}
                {f.isAI && (
                  <Tag color="purple" className={styles.tag}>
                    AI
                  </Tag>
                )}
                {f.isComingSoon && (
                  <Tag color="blue" className={styles.tag}>
                    Coming soon
                  </Tag>
                )}
                <div className={styles.icon}>{f.icon}</div>
                <div className={styles.name}>{f.name}</div>
                <div className={styles.desc}>{f.desc}</div>
                {f.isAnonymous && (
                  <div className={styles.tagRelativeAnonymousWrapper}>
                    <Tag color="purple" className={styles.tagRelativeAnonymous}>
                      <Image
                        src="/img/anonymous.png"
                        width={16}
                        height={16}
                        alt="anonymous"
                      />
                      &nbsp;&nbsp; Anonymous Responses
                    </Tag>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
