import React from "react";
import { AlertFilled, FireOutlined } from "@ant-design/icons";
import styles from "@buddieshr/styles/components/numberInstalls.module.scss";
import { getNbInstallForToday } from "@buddieshr/utils/utils";
import { Tag } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Image from "next/image";
const NumberInstalls = () => {
  const { t } = useTranslation();
  const [nbInstalls, setNbInstalls] = useState(123);
  useEffect(() => {
    setNbInstalls(getNbInstallForToday());
  }, []);

  return (
    <div className={styles.Number_installs_wrapper}>
      <div className={styles.Number_installs_inner}>
        <div className={styles.Number_installs_content}>
          <Tag
            color="red"
            style={{
              border: "none",
            }}
            className={styles.tag}
          >
            {/* <AlertFilled className={styles.Number_installs_icon} /> */}
            <Image
              className={styles.Number_installs_icon}
              src="/img/animated-emojis/Heart%20on%20Fire.png"
              alt="Hugging Face"
              width={50}
              height={50}
            />
            {nbInstalls} {t("installedLastWeek")}
          </Tag>
        </div>
      </div>
    </div>
  );
};

export default NumberInstalls;
