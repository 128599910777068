import { Button } from "antd";
import Link from "next/link";
import IndividualAppVignette from "./IndividualAppVignette";
import styles from '@buddieshr/styles/components/buddieshrAppList.module.scss'
import { PRODUCTS } from "@buddieshr/utils/constants-products";

const BuddieshrAppList = ({ appToHide = "" }) => (
  <div className={styles.wrapper}>
    <div className={styles.inner}>
      <h2 className={styles.title}>{"Other apps you'll love 😍"}</h2>
      <div className={styles.list}>
        {PRODUCTS.filter((f) => f.slug !== appToHide).map((p, i) => (
          <IndividualAppVignette app={p} key={p.slug} />
        ))}
      </div>
      <div className={styles.ctaBottom}>
        <Link
          href={`https://buddieshr.com/pricing?ref=learnmoreaboutbuddies${
            appToHide || ""
          }`}
          target="_blank"
        >
          <Button type="link" size="large">
            View Pricing
          </Button>
        </Link>
        <Link
          href={`https://buddieshr.com/?ref=learnmoreaboutbuddies${
            appToHide || ""
          }`}
          target="_blank"
        >
          <Button type="primary"  size="large">
            Learn more about BuddiesHR
          </Button>
        </Link>
      </div>
    </div>
  </div>
);

export default BuddieshrAppList;
